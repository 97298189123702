import { FormControlLabel, FormGroup, Switch, Tabs, Tab, Box, FormControl, MenuItem, Select, SelectChangeEvent, InputLabel, TextField, Input, InputAdornment, IconButton } from "@mui/material";
import moment from "moment/moment";
import { ChangeEvent, useEffect, useState, forwardRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import sweetalert2 from "sweetalert2";
import addCircuitUser from "../../core/api/requests/circuits/addCircuitUser";
import findByIdCircuitRequest from "../../core/api/requests/circuits/findByIdCircuitRequest";
import removeCircuitUser from "../../core/api/requests/circuits/removeCircuitUser";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import EModules from "../../core/enums/EModules";
import ERoutes from "../../core/enums/ERoutes";
import IAddress from "../../core/interfaces/IAddress";
import ICircuit from "../../core/interfaces/ICircuit";
import IPermission from "../../core/interfaces/IPermission";
import { RootState } from "../../core/store/store";
import constants from "../../core/utils/constants";
import defaults from "../../core/utils/defaults";
import translator from "../../core/utils/translator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import ProfileImage from "../../shared/lib/ProfileImage";
import ChangeAddressModal from "../../shared/modals/ChangeAddressModal";
import CircuitHours from "./CircuitHours";
import * as React from 'react';
import  Grid  from '@mui/material/Grid';

const permission: IPermission = {
	module: EModules.CIRCUITS,
	method: ECommonPermissions.FIND_BY_ID,
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const DEBUG_MODE = window.location.hostname === 'localhost' && true;

export default function () {
	const permissions = useSelector((state: RootState) => state.permissions);
	const dispatch = useDispatch();
	const navigator = useNavigate();
	const params = useParams();

	const [circuit, setCircuit] = useState<ICircuit>();
	const [length, setLength] = useState<number|undefined>(circuit?.length);

	const [newUser, setNewUser] = useState<string>("");

	const [modalLocation, setModalLocation] = useState<boolean>(false);
	const [modalName, setModalName] = useState<boolean>(false);

	const [error, setError] = useState<string>("");
	const [kart, setKart] = useState<string>("");
	const [time, setTime] = useState<number | undefined>(undefined);

	const [forceReload, setForceReload] = useState<boolean>(false);

	const [tabValue, setTabValue] = useState(0);

	const serviceIcons: any = {
		"free parking": "ti-direction",
		transport: "ti-car",
		restaurant: "ti-home",
		"coffee shop": "ti-home",
		bar: "ti-filter",
		terrace: "ti-comments",
		market: "ti-shopping-cart",
		wifi: "ti-world",
		"disabled access": "ti-wheelchair",
		"dressing rooms": "ti-user",
		"zona ocio": "ti-game",
		paintball: "ti-paint-roller",
	};

	// const changeLocation = async (address: IAddress) => {
	// 	if (!circuit) return;
	//
	// 	setCircuit((prevState: ICircuit | undefined) => {
	// 		if (!prevState) return prevState;
	//
	// 		return {
	// 			...prevState,
	// 			address,
	// 		};
	// 	});
	// };

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

 function CustomTabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		if (params?.id) {
			get(params.id);
		} else goBack();
	}, []);

	useEffect(() => {
		if (params?.id) {
			get(params.id);
			setForceReload(false);
		} else goBack();
	}, [forceReload]);

	const goBack = () => navigator(ERoutes.CIRCUITS_LIST);

	const get = async (id: string) => {
		const response = await findByIdCircuitRequest(dispatch, id);

		if (response.data) {
			setCircuit(response.data);
		} else goBack();
	};

	const setValue = (field: string, value: any) => {
		const c = circuit;
		if (!c) return;

		switch (field) {
			case "service":
				c.services = c?.services || [];

				if (c.services.includes(value)) {
					c.services = c.services.filter((s) => s !== value);
				} else {
					c.services.push(value);
				}

				break;
			case "kart":
				c.kartingTypes = c?.kartingTypes || [];
				c.kartingTimes = c?.kartingTimes || [];

				if (c.kartingTypes.includes(value)) {
					c.kartingTypes = c.kartingTypes.filter((s) => s !== value);
					c.kartingTimes = c.kartingTimes.filter((s) => s.kart !== value);
				} else {
					if (!time || time <= 1) {
						sweetalert2.fire(
							`Error`,
							"Es obligatorio introducir un tiempo.",
							"error"
						);
						return;
					}
					c.kartingTypes.push(value);
					c.kartingTimes.push({ kart: value, time });

					setKart("");
					setTime(0);
				}

				break;
			case "open":
				changeOpen(!c.open);
				break;
			case "premium":
				changePremium(!c.premium);
				break;
			case "name":
				c.name = value;
				break;
			case "type":
				c.type = value;
				break;
			case "website":
				c.website = value;
				break;
			case "phone":
				c.phoneNumbers = [value];
				break;
			case "length":
				c.length = parseInt(value);
				break;
			case "newUser":
				setNewUser(value);
				return;
			default:
				return;
		}

		if (c) {
			setCircuit({
				...c,
			});
		}
	};

	const removeUser = async (email: string) => {
		if (!email || !circuit?.id) return;

		const response = await removeCircuitUser(dispatch, email, circuit.id);

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Usuario ${email} removido exitosamente.`, "success")
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire(`Error`, response.data.message, "error");
		}
	};

	const addUser = async () => {
		if (!newUser || !circuit?.id) return;

		const response = await addCircuitUser(dispatch, newUser, circuit.id);

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Usuario ${newUser} agregado exitosamente.`, "success")
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire(`Error`, response.data.message, "error");
		}
	};

	const changeOpen = async (value: boolean) => {
		if (!circuit) return;
		const restricted: any = {
			action: "basicInformation",
			data: {
				open: value,
			},
		};

		const response = await updateCircuitRequest(dispatch, restricted, circuit.id);

		if (response.data) {
			setCircuit(response.data);
		}
	};

	const changePremium = async (value: boolean) => {
		if (!circuit) return;
		const restricted: any = {
			action: "restrictedInformation",
			data: {
				premium: value,
			},
		};

		const response = await updateCircuitRequest(dispatch, restricted, circuit.id);

		if (response.data) {
			setCircuit(response.data);
		}
	};

	const update = async () => {
		if (!circuit) return;

		const basicInformation: any = {
			action: "basicInformation",
			data: {
				website: circuit.website,
				length:
					typeof circuit.length === "string"
						? parseInt(circuit.length)
						: circuit.length,
				services: circuit.services,
				kartingTypes: circuit.kartingTypes,
				kartingTimes: circuit.kartingTimes,
				address: circuit.address,
			},
		};

		const restricted: any = {
			action: "restrictedInformation",
			data: {
				name: circuit.name,
				type: circuit.type,
			},
		};

		if (DEBUG_MODE) {
			console.log('�� Debug Mode Enabled');
			console.log('Circuit Update Data:', {
				basicInformation,
				restricted,
				phoneNumber: circuit.phoneNumbers?.[0],
				circuitId: circuit.id
			});
			
			// Simular respuesta exitosa
			sweetalert2.fire(
				`Debug Mode`,
				`Datos listos para enviar al backend`,
				"info"
			);
			return;
		}

		if (circuit.phoneNumbers && circuit.phoneNumbers.length) {
			const phoneNumber: any = {
				action: "phoneNumber",
				data: {
					number: circuit.phoneNumbers[0],
				},
			};
			await updateCircuitRequest(dispatch, phoneNumber, circuit.id);
		}

		const response = await Promise.all([
			updateCircuitRequest(dispatch, basicInformation, circuit.id),
			updateCircuitRequest(dispatch, restricted, circuit.id),
		]);

		if (
			response.length > 0 &&
			response[0].statusCode === EHttpStatusCodes.ACCEPTED
		) {
			sweetalert2
				.fire(
					`Excelente`,
					`Circuito ${circuit.name} modificado exitosamente.`,
					"success"
				)
				.then(() => {
					setCircuit(response[0].data);
				});
		} else {
			sweetalert2.fire(
				`Error`,
				response[0].data.message || response[1].data.message,
				"error"
			);
		}
	};

	const changeLocation = async (address: IAddress) => {
		if (!circuit) return;

		setCircuit((prevState: ICircuit | undefined) => {
			if (!prevState) return prevState;

			return {
				...prevState,
				address,
			};
		});
	};

	if (!circuit)
		return (
			<SecureView permission={permission}>
				<div></div>
			</SecureView>
		);
	return (
		<SecureView permission={permission}>
			<ChangeAddressModal
				show={modalLocation}
				onSubmit={changeLocation}
				onClose={() => setModalLocation(false)}
			/>

			<Card className={"col-12"}>
				<CardBody>
					<Row style={{ justifyContent: "center", marginBottom: '2rem' }}>
						<ProfileImage uri={circuit.coverPicture || defaults.circuitCover} />
					</Row>
					<Row style={{ justifyContent: "center", alignItems: 'center' }}>
						<h3 className={'mt-2 mr-2'}>{circuit?.name}</h3>
							<i className={"ti-pencil text-gray p-2 pointer"}></i>
					</Row>
					<Row style={{ justifyContent: "center", marginTop: '1rem' }}>
						<p className={'text-gray text-md'}>ID: {circuit.id}</p>
					</Row>
					<Row style={{ justifyContent: "center", marginTop: '1rem' }}>
						<i className={"ti-user text-gray px-2"}></i>
						<p className={"text-gray text-md"}>Cliente desde: {circuit?.createdAt &&
						moment(circuit.createdAt).format("MMMM YYYY")}</p>
					</Row>
					<Row style={{ justifyContent: "center", marginTop: '1rem', marginBottom: '2rem' }}>
						<FormGroup row={true}>
							<FormControlLabel
								control={
									<Switch
										color={"primary"}
										onChange={(e, v) => setValue("premium", v)}
										checked={circuit.premium || false}
									/>
								}
								label="Premium"
							/>
							<FormControlLabel
								control={
									<Switch
										color={"primary"}
										onChange={(e, v) => setValue("open", v)}
										checked={circuit.open || false}
									/>
								}
								label="Abierto"
							/>
						</FormGroup>
					</Row>



					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={tabValue} onChange={handleChange} centered aria-label="basic tabs example">
								<Tab label="Información General" {...a11yProps(0)} />
								<Tab label="Horarios" {...a11yProps(1)} />
								<Tab label="Tipos de Kart" {...a11yProps(2)} />
								<Tab label="Servicios" {...a11yProps(3)} />
							</Tabs>
						</Box>

						<CustomTabPanel value={tabValue} index={0}>
							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
									<InputLabel id="demo-simple-select-standard-label">TIPO DE CIRCUITO</InputLabel>
									<Select
										labelId="demo-simple-select-standard-label"
										id="circuit-type"
										value={circuit.type}
										onChange={(e) => setValue("type", e.target.value)}
										label="Tipo de circuito"
									>
										<MenuItem value="-1">
											<em>None</em>
										</MenuItem>
										<MenuItem value={"indoor"}>Indoor</MenuItem>
										<MenuItem value={"outdoor"}>Outdoor</MenuItem>
										<MenuItem value={"indoor/outdoor"}>Indoor / Outdoor</MenuItem>
									</Select>
								</FormControl>

								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
									<TextField id="length" type={'number'} label="LONGITUD DEL CIRCUITO (metros)" variant="standard"	value={circuit?.length}
																				onChange={(e) => setValue("length", e.target.value)} />
								</FormControl>
							</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
								<TextField id="website" label="SITIO WEB" variant="standard" 	value={circuit?.website}
																			onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '40%' }}>
								<TextField id="phone" label="CONTACTO (añadir prefijo de país)" variant="standard" 	value={
									(circuit.phoneNumbers || []).length > 0
										? circuit.phoneNumbers[0].number
										: ""
								}
																			onChange={(e) => setValue("phone", e.target.value)} />
								</FormControl>
							</Row>
							<Row style={{ marginTop: '1rem' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
									<InputLabel htmlFor="standard-adornment-password">DIRECCIÓN</InputLabel>
								<Input id="length" value={circuit?.address.address} disabled
																			onChange={(e) => setValue("address", e.target.value)}
																			endAdornment={
																				<InputAdornment position="end">
																					<IconButton
																						aria-label={'change-location'}
																						onClick={() => setModalLocation(!modalLocation)}
																					>
																						<i className={"ti-pencil"}></i>
																					</IconButton>
																				</InputAdornment>
																			}/>
							</FormControl>
						</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="zip" label="CÓDIGO POSTAL" type={'number'} variant="standard" 	value={circuit?.address.postalCode} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="city" label="CIUDAD" variant="standard" 	value={circuit?.address.city} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="region" label="REGIÓN" variant="standard" 	value={circuit?.address.province} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
							</Row>

							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'center' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="country" label="PAÍS" variant="standard" 	value={circuit?.address.country} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="latitude" label="lATITUD" type={'number'} variant="standard" 	value={circuit?.address.latitude} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
									<TextField id="longitude" label="LONGITUD" type={'number'} variant="standard" 	value={circuit?.address.longitude} disabled
																				onChange={(e) => setValue("website", e.target.value)} />
								</FormControl>
							</Row>

						</CustomTabPanel>

						<CustomTabPanel value={tabValue} index={1}>
							<CircuitHours forceReload={() => setForceReload(true)} circuit={circuit} />
						</CustomTabPanel>

						<CustomTabPanel value={tabValue} index={2}>
							<Row style={{ justifyContent: "space-between", marginTop: '1rem', alignItems: 'end' }}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '45%' }}>
									<TextField id="longitude" label="Introduce el nombre del kart..." variant="standard" value={kart}
																				onChange={(e) => setKart(e.target.value)}
									/>
								</FormControl>
								<FormControl variant="standard" sx={{ m: 1, minWidth: '45%' }}>
									<TextField id="longitude" label="Introduce el mejor tiempo..." type={'number'} variant="standard" value={time}
																				onChange={(e) => setTime(Number(e.target.value))} />
								</FormControl>

								<Button className={'rounded-full btn-ligth'}
									onClick={() => setValue("kart", kart)}
									id="button-addon2"
								>
									<i className={"ti-check icon-green text-lg"}></i>
								</Button>
							</Row>

							{circuit?.kartingTypes?.map((kart) => {
								const kbt = circuit?.kartingTimes?.find((c) => c.kart === kart);
								let time = 0;

								if (kbt) {
									time = kbt.time;
								}

								return (
									<Row style={{ marginTop: '1rem', alignItems: 'end' }}>
										<FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
											<Input id="length" value={`${kart} - Mejor tiempo: ${time}`} disabled
																		endAdornment={
																			<InputAdornment position="end">
																				<IconButton
																					aria-label={'edit-kart'}
																					onClick={() => {
																						setKart(kart);
																						setTime(time);
																						setValue("kart", kart);
																					}}
																				>
																					<i className={"ti-pencil"}></i>
																				</IconButton>
																				<IconButton
																					aria-label={'delete-kart'}
																					onClick={() => setValue("kart", kart)}
																				>
																					<i className={"ti-trash text-red"}></i>
																				</IconButton>
																			</InputAdornment>
																		}/>
										</FormControl>
									</Row>
								);
							})}
						</CustomTabPanel>
						<CustomTabPanel value={tabValue} index={3}>
							<Grid container spacing={4}>
									{constants.circuits.services.map((service, i) => {
										return (
											<Grid item xs={6}>
												<FormControl variant="standard" sx={{ m: 1, mr:3, minWidth: '100%' }}>
													<Input id="length" value={translator(service.name)} disabled
																				startAdornment={
																					<InputAdornment position="start">
																					<i className={serviceIcons[service.name]}/>
																					</InputAdornment>
																				}
																				endAdornment={
																					<InputAdornment position="end">
																						<InputGroup.Checkbox className={'service-checkbox'}
																							onChange={() => setValue("service", service.id)}
																							checked={(circuit.services || []).includes(service.id)}
																							aria-label={service.name}
																						/>
																					</InputAdornment>
																				}/>
												</FormControl>
											</Grid>
										);
									})}
							</Grid>
						</CustomTabPanel>
					</Box>
				</CardBody>

				<CardBody className={'text-center'}>
					<Button
						variant="success"
						type="button"
						onClick={update}
						className={"mt-3 rounded-md"}
					>
						Guardar Cambios
					</Button>
				</CardBody>
			</Card>
		</SecureView>
	);
}
