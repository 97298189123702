import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import loginRequest from "../../../core/api/requests/auth/loginRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import { setSession } from "../../../core/store/slices/sessionSlice";
import { getSession, saveSession } from "../../../core/utils/sessionStorage";
import translator from "../../../core/utils/translator";
import Swal from "sweetalert2";
import PasswordInput from "../../../shared/lib/PasswordInput";
import "./login.css";

const TITLE = "login";

export default function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState({ email: "", password: "" });
	const [latitude, setLatitude] = useState<number>(0);
	const [longitude, setLongitude] = useState<number>(0);
	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const validatePassword = (password: string) => {
		return password.length >= 6;
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		let valid = true;
		let emailError = "";
		let passwordError = "";

		if (!validateEmail(email)) {
			emailError = "Correo incorrecto.";
			valid = false;
		}

		if (!validatePassword(password)) {
			passwordError = "La contraseña debe tener al menos 6 caracteres.";
			valid = false;
		}

		setErrors({ email: emailError, password: passwordError });



		if (valid) {
			e.preventDefault();

			const response = await loginRequest(dispatch, {
			  email,
			  password,
			  refreshToken: false,
			  context: {
				latitude,
				longitude,
			  },
			});

			if (response.statusCode === EHttpStatusCodes.OK) {
			  saveSession(response.data);
			  return redirectToDashboard();
			} else {
			  Swal.fire({
							title: 'Error',
							html: translator(response.data.message),
							icon: 'error',
							confirmButtonText: 'Entendido',
			  	}
			  );
			}
		}
	};


	const dispatch = useDispatch();

	const navigate = useNavigate();

	const redirectToDashboard = () => navigate(ERoutes.DASHBOARD);

	useEffect(() => {
	  const data = getSession();
	  if (data) {
		dispatch(setSession(data));
		redirectToDashboard();
	  }

	  navigator.geolocation.getCurrentPosition((position) => {
		setLatitude(position.coords.latitude);
		setLongitude(position.coords.longitude);
	  });
	}, []);


	return (
		<div className="flex-center full-height">
			<div className="form-container w-75 login-form-container">
				<div className="logo-container">
					<img src="/images/Onpole.png" alt="Logo" />
				</div>
				<h1 className="login-title">¡Bienvenido OnPole Manager!</h1>
				<form onSubmit={handleSubmit}>
					<input
						className={errors.email ? "input-container-error" : ""}
						type="email"
						placeholder="Correo Electrónico"
						value={email}
						onChange={e => setEmail(e.target.value)}
						required
					/>
					{errors.email && <span className="error">{errors.email}</span>}
					<PasswordInput
						className={errors.password ? "input-container-error" : ""}
						placeholder="Contraseña"
						required
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
					{errors.password && <span className="error">{errors.password}</span>}
					<Link to="/auth/password-recovery" className="link">¿Has olvidado tu contraseña?</Link>
					<button className={""} type="submit">
						Iniciar Sesión
					</button>
				</form>
			</div>
			<div
				className="image-container w-25"
				style={{ backgroundImage: `url(/images/bannerRight.png)` }}
			/>
		</div>
	);
}
