interface cardBodyParameters {
	children: any;
	title?: string;
	buttons?: React.ReactNode; // Añadido para los botones
	className?: string
}

export default function CardBody(data: cardBodyParameters) {
	return (
		<div className={`card-body ${data.className}`}>
			<div className="card-title-header">
				{data.title ? (
					<h4 className="card-title">{data.title}</h4>
				): (<></>)}
				<div className="card-buttons">{data.buttons}</div>
			</div>
			{data.children && data.children}
		</div>
	);
}
