import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import getMyCircuitsRequest from "../../core/api/requests/me/getMyCircuitsRequest";
import getMyProfileRequest from "../../core/api/requests/me/getMyProfileRequest";
import getFrontendUtilitiesRequest from "../../core/api/requests/utils/getFrontUtilitiesRequest";
import ERoutes from "../../core/enums/ERoutes";
import ICircuit from "../../core/interfaces/ICircuit";
import IMenuItem from "../../core/interfaces/IMenuItem";
import { endLoading, startLoading } from "../../core/store/slices/loadingSlice";
import {
	setCircuit,
	unselectCircuit,
} from "../../core/store/slices/selectedCompanySlice";
import { RootState } from "../../core/store/store";
import capitalizeFirstLetter from "../../core/utils/capitalizeFirstLetter";
import hasPermission from "../../core/utils/hasPermission";
import translator from "../../core/utils/translator";
import getVersion from "../../version";
import logo from "../styles/images/logo-transparent-red.png";
import menuList from "./menuList";

import offersSelected from "../styles/images/offers-selected.png";
import offers from "../styles/images/offers.png";

import circuitSelected from "../styles/images/circuits-selected.png";
import circuits from "../styles/images/circuits.png";

import racingSelected from "../styles/images/racing-selected.png";
import racing from "../styles/images/racing.png";

import eventsSelected from "../styles/images/events-selected.png";
import events from "../styles/images/events.png";

import usersSelected from "../styles/images/UsuariosSelected.svg";
import users from "../styles/images/Usuarios.svg";

import verificationsSelected from "../styles/images/VerificacionSelected.svg";
import verifications from "../styles/images/Verificacion.svg";

import denunciaSelected from "../styles/images/denunciasSelected.svg";
import denuncia from "../styles/images/denuncias.svg";

import tips from "../styles/images/consejos.svg";
import tipsSelected from "../styles/images/consejosSelected.svg";

import kart from "../styles/images/kart.svg";
import kartSelected from "../styles/images/kartSelected.svg";

import analiticas from "../styles/images/analiticas.svg";
import analiticasSelected from "../styles/images/analiticasSelected.svg";


export default function Sidebar() {
	const dispatch = useDispatch();
	const permissions = useSelector((state: RootState) => state.permissions);
	const availableCircuits = useSelector(
		(state: RootState) => state.availableCircuits
	);
	const selectedCompany = useSelector(
		(state: RootState) => state.selectedCompany
	);
	const userMe = useSelector((state: RootState) => state.userMe);
	const navigator = useNavigate();

	const [version, setVersion] = useState<string>("");
	const [menu, setMenu] = useState<IMenuItem[]>([]);

	const selectCircuit = (e: any) => {
		e.preventDefault();

		if (!e.target.value) return;

		if (e.target.value === "-1") {
			dispatch(unselectCircuit());
			return;
		}

		const circuit = availableCircuits.find(
			(ac: ICircuit) => ac.id === e.target.value
		);
		if (!circuit) return;

		dispatch(setCircuit(circuit));
	};

	const validateMenuByPermissions = () => {
		const menuTemp: IMenuItem[] = [];
		menuList.forEach((m) => {
			if (m.forCircuit && !selectedCompany?.circuit) return;

			if (!m.permission) {
				menuTemp.push(m);
			} else if (hasPermission(permissions, m.permission)) {
				menuTemp.push(m);
			}
		});

		setMenu(menuTemp);
	};

	const getSelectedCompany = () => {
		const sc = sessionStorage.getItem("sc");
		if (sc) {
			dispatch(setCircuit(JSON.parse(sc)));
		}
	};

	const getMyProfile = async () => await getMyProfileRequest(dispatch);

	const getMyCompanyProfile = async () => {};

	const getMyCompanies = async () => await getMyCircuitsRequest(dispatch);

	const getFrontUtilities = async () =>
		await getFrontendUtilitiesRequest(dispatch);

	const initialRequests = async () => {
		dispatch(startLoading());
		getSelectedCompany();
		await Promise.all([getMyProfile(), getMyCompanies(), getFrontUtilities()]);
		dispatch(endLoading());
	};

	useEffect(() => {
		initialRequests();
	}, []);

	useEffect(() => validateMenuByPermissions(), [permissions]);

	useEffect(() => {
		(async () => {
			dispatch(startLoading());
			await getMyCompanyProfile();
			validateMenuByPermissions();
			dispatch(endLoading());
		})();
	}, [selectedCompany.circuit]);

	useEffect(() => {
		if (userMe.user?.firstInit) navigator(ERoutes.FORCE_PASSWORD_CHANGE);
	}, [userMe.user]);

	return (
		<>
			<aside className="left-sidebar pt-2" id={"sidebar"}>
				<div className="aside-logo">
					<div className={"company-information"}>
						<a href={'/circuits'}>
							<img src={logo} />
						</a>
					</div>
					{userMe?.user && (
						<div className={"user-profile mt-4"}>
							<span className="hide-menu">
								{userMe?.user.name} {userMe.user.lastName}
							</span>
							<small>{userMe.user.email}</small>
							<small>{capitalizeFirstLetter(translator(userMe.user.role.name))}</small>
						</div>
					)}

					<hr />
					{availableCircuits.length ? (
						<div className={"form-group company-selector"}>
							<div className="select-icon-container">
								<img src={circuits} alt={"circuit"} className="select-icon" />
								<select
									className={"custom-select"}
									onChange={selectCircuit}
									value={selectedCompany?.circuit?.id}
									defaultValue={"-1"}
								>
									<option value={"-1"} hidden>
										Seleccionar Circuito
									</option>
									{availableCircuits.map((ac: ICircuit) => (
										<option key={ac.id} value={ac.id}>
											{ac.name}
										</option>
									))}
								</select>
								<a
									href={selectedCompany?.circuit && selectedCompany?.circuit.id ? ERoutes.CIRCUITS_VIEW.replace(':id', selectedCompany?.circuit?.id) : ''}
									className="btn float-right btn-light shadow border ml-2" style={{borderRadius: '12px'}}
								>
									<i className={'ti-search'}></i>
								</a>
							</div>
						</div>
					) : (
						<div className={"alert alert-warning"}>No tienes circuitos asignados</div>
					)}

					<hr />
				</div>
				<nav className="sidebar-nav">
					<ul className="menu-buttons">
						{menu.map((m) => {
							const selected = m.redirectTo === document.location.pathname;
							if (m.name === "Separador") return <hr />;
							return (
								<li
									key={`${m.name}${Date.now()}`}
									className={`${selected ? "selected" : ""}`}
								>
									<Link to={m.redirectTo}>
										{m.icon.includes("rc-") ? (
											<>
												{selected ? (
													<>
														{m.icon === "rc-analiticas" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={analiticasSelected}
																alt={"analiticas"}
															/>
														)}
														{m.icon === "rc-kart" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={kartSelected}
																alt={"kart"}
															/>
														)}
														{m.icon === "rc-tips" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={tipsSelected}
																alt={"tips"}
															/>
														)}
														{m.icon === "rc-denuncia" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={denunciaSelected}
																alt={"denuncia"}
															/>
														)}
														{m.icon === "rc-verifications" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={verificationsSelected}
																alt={"verifications"}
															/>
														)}
														{m.icon === "rc-users" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={usersSelected}
																alt={"users"}
															/>
														)}
														{m.icon === "rc-offers" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={offersSelected}
																alt={"offers"}
															/>
														)}

														{m.icon === "rc-circuit" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={circuitSelected}
																alt={"circuit"}
															/>
														)}

														{m.icon === "rc-events" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={eventsSelected}
																alt={"events"}
															/>
														)}

														{m.icon === "rc-racing" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={racingSelected}
																alt={"racing"}
															/>
														)}
													</>
												) : (
													<>
														{m.icon === "rc-analiticas" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={analiticas}
																alt={"analiticas"}
															/>
														)}
														{m.icon === "rc-kart" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={kart}
																alt={"kart"}
															/>
														)}
														{m.icon === "rc-tips" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={tips}
																alt={"tips"}
															/>
														)}
														{m.icon === "rc-denuncia" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={denuncia}
																alt={"denuncia"}
															/>
														)}
														{m.icon === "rc-verifications" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={verifications}
																alt={"verifications"}
															/>
														)}
														{m.icon === "rc-users" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={users}
																alt={"users"}
															/>
														)}
														{m.icon === "rc-offers" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={offers}
																alt={"offers"}
															/>
														)}

														{m.icon === "rc-circuit" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={circuits}
																alt={"circuit"}
															/>
														)}

														{m.icon === "rc-events" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={events}
																alt={"events"}
															/>
														)}

														{m.icon === "rc-racing" && (
															<img
																className={"icon-onpole"}
																width={15}
																height={"auto"}
																src={racing}
																alt={"racing"}
															/>
														)}
													</>
												)}
											</>
										) : (
											<i className={m.icon}></i>
										)}
										{m.name}
									</Link>
								</li>
							);
						})}
					</ul>

					<div className={"buttons-footer"}>
						<ul>
							<li>
								<Link to={ERoutes.AUTH_LOGOUT}>
									<i className="icon-logout"></i> Cerrar Sesión
								</Link>
							</li>
						</ul>
						<hr />
					</div>
				</nav>
			</aside>
		</>
	);
}
