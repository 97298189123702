import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/store/store";
import { useNavigate } from "react-router-dom";
import SecureView from "../../shared/common/SecureView";
import { Row } from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import * as React from "react";
import { useState } from "react";

const permission: IPermission = {
	module: EModules.TIPS,
	method: ECommonPermissions.FIND,
}

export default function () {
	const permissions = useSelector((state: RootState) => state.permissions)
	const dispatch = useDispatch();
	const navigator = useNavigate();

	const [ yearly, setYearly ] = useState<boolean>(true);
	const [ basicPlan, setBasicPlan ] = useState<boolean>(true);

	const createData = (name: string,
																					basic: boolean,
																					advanced: boolean,
	) => {
		return { name, basic, advanced };
	};

	const rows = [
		createData("Posicionar Circuito en la App", true, true),
		createData("Gestión de Información", true, true),
		createData("Análisis de Métricas", true, true),
		createData("Análisis de Métricas Avanzadas", false, true),
		createData("Publicación de Eventos", false, true),
		createData("Gestión de Inscripciones a Eventos*", false, true),
		createData("Servicio de Soporte Rápido", false, true),
	];

	return <SecureView permission={permission}>
		<Card className={"col-12"}>
			<CardBody>
				<Row className={'mb-4'}>
					<h2>Planes</h2>
					<hr className={"w-full border"} />
				</Row>
				<div className={'border-red rounded bl-lg-red p-4 mb-4'}>
					<Row className={'mb-2'}>
						<h4>
							<i className={'ti-rocket mr-2 text-red'} />
							Mejora a OnPole Manager Avanzado
						</h4>
					</Row>
					<p className={'text-gray text-md'}>
						Mejora tu plan a OnPole Manager Avanzado para desbloquear inmediatamente el acceso a todos los servicios exclusivos de la plataforma como eventos, tandas, ofertas y carreras.
					</p>
				</div>
				<hr className={"w-full border"} />

				<Row style={{justifyContent: 'space-between', alignItems: "start"}}>
					<div>
						<h4>Facturación</h4>
						<Row className='bg-gray-ligth rounded-xl p-1 center'>
							<div className={yearly ? 'text-gray bg-gray-ligth mr-2 pointer px-4 py-2' : 'bg-white rounded-xl mr-2 pinter px-4 py-2'} onClick={() => setYearly(!yearly)}>Mensual</div>
							<div className={!yearly ? 'text-gray bg-gray-ligth pointer px-4 py-2' : 'bg-white rounded-xl pinter px-4 py-2'} onClick={() => setYearly(!yearly)}>Anual</div>
						</Row>
					</div>

					<div>
						<Row className={'center mb-4'}>
							<h4 className={'text-gray mr-2 mt-2'}>Manager Básico</h4>
							<span className={'px-1 bg-gray text-white text-xs rounded'}>-16%</span>
						</Row>
						<p className={'text-xl mb-0'}>{yearly ? '24.99€' : '29.99€'}<span className={'text-xs'}> / mes</span></p>
						<p className={' text-xs text-gray'}><s>{yearly ? '29.99€' : '34.99€'} / mes</s></p>
						<div className={'mt-5 text-center'}>
							<p className={basicPlan ? 'text-red' : 'bg-red text-white rounded-xl px-4 py-2 pointer'}
										onClick={() => {
											if(!basicPlan)
											setBasicPlan(!basicPlan);
										}}>
								{basicPlan ? 'Plan Actual' : 'Suscríbete'}
							</p>
						</div>
					</div>

					<div>
						<Row className={'center mb-4'}>
							<h4 className={'text-gray mr-2 mt-2'}>Manager Avanzado</h4>
							<span className={'px-1 bg-gray text-white text-xs rounded'}>-16%</span>
						</Row>
						<p className={'text-xl mb-0'}>{yearly ? '49.99€' : '59.99€'}<span className={'text-xs'}> / mes</span></p>
						<p className={' text-xs text-gray'}><s>{yearly ? '59.99€' : '74.99€'}</s></p>
						<div className={'mt-5 text-center'}>
							<p className={!basicPlan ? 'text-red' : 'bg-red text-white rounded-xl px-4 py-2 pointer'}
										onClick={() => {
											if(basicPlan)
												setBasicPlan(!basicPlan);
										}}>
								{!basicPlan ? 'Plan Actual' : 'Suscríbete'}
							</p>
						</div>
					</div>
				</Row>

				<div className={'mb-4'}>
				{rows.map((elm) =>{
					return <Row className={'b-b py-3'}>
						<div style={{width:'33%'}} className={'text-md text-gray'}>{elm.name}</div>
						<div style={{width:'33%', textAlign: 'center'}}>{elm.basic ? <i className={'ti-check text-lg text-red'}/>: ''}</div>
						<div style={{width:'33%', textAlign: 'end'}}>{elm.advanced ? <i style={{marginRight: '5rem'}} className={'ti-check text-lg text-red'}/>: ''}</div>
					</Row>
				})}
				</div>

				<p className={'text-xs text-gray'}>*Comisiones y condiciones especiales en pagos dentro de la aplicación.</p>
			</CardBody>
		</Card>
	</SecureView>
}
