import Body from "../../../shared/common/Body";
import {useDispatch} from "react-redux";
import {Link, useLocation, useNavigate, useParams, useRoutes} from "react-router-dom";
import {Alert} from "react-bootstrap";
import ERoutes from "../../../core/enums/ERoutes";
import {useState} from "react";
import passwordRecoveryVerificationRequest, {
  IPasswordRecoveryVerificationRequest
} from "../../../core/api/requests/auth/passwordRecoveryVerificationRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import PasswordInput from "../../../shared/lib/PasswordInput";
import translator from "../../../core/utils/translator";

export default function PasswordRecoveryVerify() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const [ password, setPassword ] = useState<string>("");
  const [ passwordRepeat, setPasswordRepeat ] = useState<string>("");

  const [errors, setErrors] = useState({ password: "", repeatPassword: "" });

  const request = async (e: any) => {
    e.preventDefault();
    if (!password) return;

    const data: IPasswordRecoveryVerificationRequest = {
      password,
      token: new URLSearchParams(search).get('t') || ''
    }

    const response = await passwordRecoveryVerificationRequest(
      dispatch,
      data,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `clave modificada exitosamente.`,
        'success',
      ).then(() => navigator(ERoutes.AUTH_LOGIN))
    } else {
      sweetalert2.fire({
         title: 'Error',
         html: response.data.message,
         icon: 'error',
         confirmButtonText: 'Entendido',
       }
      )
    }
  }

  const setAndValidatePassword = (value: string) => {
    setPassword(value);

    if (value.length < 8) {
      setErrors({password: 'la clave debe contener mas de 8 caracteres', repeatPassword: errors.repeatPassword});
      return;
    }

    if (passwordRepeat !== "" && value !== passwordRepeat) {
      setErrors({password: 'Las claves deben coincidir', repeatPassword: errors.repeatPassword});
      return;
    }

    setErrors({password: '', repeatPassword: errors.repeatPassword});
    return
  }

  const setAndValidateRepeatPassword = (value: string) => {
    setPasswordRepeat(value);

    if (value !== password) {
      setErrors({password: errors.password, repeatPassword: 'Las claves deben coincidir'});
      return;
    }

    setErrors({password: errors.password, repeatPassword: ''});
    return
  }

  return (
    <section id="wrapper" className={"fullWidth"}>
      <div className="flex-center full-height">
        <div className="form-container password-recovery w-75">
          <div className="logo-container">
            <img src="/images/Onpole.png" alt="Logo" />
          </div>
          <h1 className="mb-5">Recuperar contraseña</h1>
          <p className="subtitle mb-5">
            Ya casi está. Introduce tu nueva contraseña y listo
          </p>
          <form className="form-horizontal form-material" onSubmit={request}>
            <div className="form-group ">
              <div className="col-xs-12">
                <PasswordInput
                 className={errors.password ? "input-container-error" : ""}
                 placeholder="Contraseña"
                 required
                 value={password}
                 onChange={e => setAndValidatePassword(e.target?.value)}
                />
                {errors.password && <span className="error">{errors.password}</span>}
              </div>
            </div>
            <div className="form-group ">
              <div className="col-xs-12">
                {/*<input className="form-control" type="password" required={true} placeholder="Repetir contraseña" value={passwordRepeat} onChange={(e) => setAndValidateRepeatPassword(e.target?.value)} />*/}
                <PasswordInput
                 className={errors.repeatPassword ? "input-container-error" : ""}
                 placeholder="Repetir contraseña"
                 required
                 value={passwordRepeat}
                 onChange={e => setAndValidateRepeatPassword(e.target?.value)}
                />
                {errors.repeatPassword && <span className="error">{errors.repeatPassword}</span>}
              </div>
            </div>

            <div className="form-group text-center">
              <div className="p-b-20">
                <button className={"w-auto h-auto p-xl-3 disabled"} type="submit">
                  Cambiar contraseña
                </button>
              </div>
            </div>

            <div className="form-group m-b-0 text-center">
              <Link to="/auth/login" className="link">
                <span>&larr;</span>Volver a Inicio de Sesión
              </Link>
            </div>
          </form>

        </div>
      <div
       className="image-container w-25"
       style={{ backgroundImage: `url(/images/bannerRight.png)` }}
      />
      </div>
    </section>
  )
}
