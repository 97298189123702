import { Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../lib/Card";
import CardBody from "../lib/CardBody";

interface ISummaryParameters {
	children: any[];
	link?: string;
	linkLabel?: string;
	onClick?: () => void;
}

export default function(data: ISummaryParameters) {

	return (
		<Row>
			<Card className={"col-12"}>
				<CardBody>
					<div style={{ justifyContent: "space-between", alignItems: "center", borderRadius: "1rem" }}
										className={"row p-2 border border-dark"}>
						{data.children.map((elm) => {
							return <div className={"px-5 py-2 "}>
								<p className={"text-uppercase"} style={{ fontSize: "2rem" }}>{elm.title}</p>
								<p style={{ fontSize: "2rem" }}><strong>{elm.value}</strong></p>
							</div>;
						})}
						{data.link &&
							<div className={"col-3"}>
								<Link to={data.link || ''} className="btn btn-info float-right">
									{data.linkLabel}
								</Link>
							</div>}
						{!data.link && data.onClick &&
							<div className={"col-3"}>
								<Button
									onClick={data.onClick}
									className="btn btn-info float-right"
								>{data.linkLabel}
								</Button>
							</div>
						}
					</div>

				</CardBody>
			</Card>
		</Row>
	);
}
