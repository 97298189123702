import {Route, Routes} from "react-router-dom";
import ERoutes from "../enums/ERoutes";
import Home from "../../components/home/home";
import React from "react";
import Login from "../../components/auth/login/Login";
import PasswordRecovery from "../../components/auth/password-recovery/PasswordRecovery";
import PasswordRecoveryVerify from "../../components/verify/password-recovery/PasswordRecoveryVerify";
import AccountActivationVerify from "../../components/verify/account-activation/AccountActivationVerify";

export default function NoAuthRoutes() {
  return <Routes>
      <Route path={ERoutes.HOME} element={<Home />} />
      <Route path={ERoutes.AUTH_LOGIN} element={<Login />} />
      <Route path={ERoutes.AUTH_PASSWORD_RECOVERY_REQUEST} element={<PasswordRecovery />} />
      <Route path={ERoutes.VERIFY_PASSWORD_RECOVERY} element={<PasswordRecoveryVerify />} />
      <Route path={ERoutes.VERIFY_ACCOUNT_ACTIVATION} element={<AccountActivationVerify />} />
  </Routes>
}
