import { Route, Routes } from "react-router-dom";
import Logout from "../../components/auth/logout/Logout";
import CircuitEventsList from "../../components/circuits/CircuitEventsList";
import CircuitList from "../../components/circuits/CircuitList";
import CircuitModify from "../../components/circuits/CircuitModify";
import CompanyNew from "../../components/circuits/CircuitNew";
import CircuitOfferList from "../../components/circuits/CircuitOfferList";
import CircuitProductList from "../../components/circuits/CircuitProductList";
import CircuitRacingList from "../../components/circuits/CircuitRacingList";
import MyCircuit from "../../components/circuits/MyCircuit";
import Dashboard from "../../components/dashboard/Dashboard";
import UserModify from "../../components/users/UserModify";
import UserNew from "../../components/users/UserNew";
import UsersList from "../../components/users/UsersList";
import ReportList from "../../components/utils/ReportList";
import TipsList from "../../components/utils/TipsList";
import ValidationList from "../../components/utils/ValidationList";
import ForcePasswordChange from "../../components/verify/force-change-password/ForceChangePassword";
import ERoutes from "../enums/ERoutes";
import PlansInfo from "../../components/plans/PlansInfo";

export default function SecureRoutes() {
  return (
    <Routes>
      <Route path={ERoutes.DASHBOARD} element={<Dashboard />} />

      <Route path={ERoutes.USERS_LIST} element={<UsersList />} />
      <Route path={ERoutes.USERS_CREATE} element={<UserNew />} />
      <Route path={ERoutes.USERS_VIEW} element={<UserModify />} />

      <Route path={ERoutes.CIRCUITS_LIST} element={<CircuitList />} />
      <Route path={ERoutes.CIRCUITS_VIEW} element={<CircuitModify />} />
      <Route path={ERoutes.CIRCUITS_CREATE} element={<CompanyNew />} />

      <Route path={ERoutes.AUTH_LOGOUT} element={<Logout />} />

      <Route path={ERoutes.TIPS_LIST} element={<TipsList />} />

      <Route path={ERoutes.REPORTS_LIST} element={<ReportList />} />

      <Route path={ERoutes.VALIDATIONS_LIST} element={<ValidationList />} />

      <Route path={ERoutes.PLANS} element={<PlansInfo />} />

      <Route
        path={ERoutes.CIRCUIT_PRODUCT_LIST}
        element={<CircuitProductList />}
      />

      <Route
        path={ERoutes.CIRCUIT_EVENTS_LIST}
        element={<CircuitEventsList />}
      />

      <Route path={ERoutes.CIRCUIT_OFFER_LIST} element={<CircuitOfferList />} />

      <Route
        path={ERoutes.CIRCUIT_RACING_LIST}
        element={<CircuitRacingList />}
      />

      <Route path={ERoutes.MY_CIRCUIT} element={<MyCircuit />} />
      <Route
        path={ERoutes.FORCE_PASSWORD_CHANGE}
        element={<ForcePasswordChange />}
      />
    </Routes>
  );
}
