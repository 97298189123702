import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import sweetalert2 from "sweetalert2";
import addCircuitUser from "../../core/api/requests/circuits/addCircuitUser";
import findByIdCircuitRequest from "../../core/api/requests/circuits/findByIdCircuitRequest";
import removeCircuitUser from "../../core/api/requests/circuits/removeCircuitUser";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import uploadCircuitRequest from "../../core/api/requests/circuits/uploadCircuitRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import EModules from "../../core/enums/EModules";
import ERoutes from "../../core/enums/ERoutes";
import IAddress from "../../core/interfaces/IAddress";
import ICircuit from "../../core/interfaces/ICircuit";
import IPermission from "../../core/interfaces/IPermission";
import { RootState } from "../../core/store/store";
import constants from "../../core/utils/constants";
import defaults from "../../core/utils/defaults";
import translator from "../../core/utils/translator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import ProfileImage from "../../shared/lib/ProfileImage";
import ChangeAddressModal from "../../shared/modals/ChangeAddressModal";
import CircuitHours from "./CircuitHours";
import moment from "moment/moment";
import "moment/locale/es";

const permission: IPermission = {
	module: EModules.CIRCUITS,
	method: ECommonPermissions.FIND_BY_ID,
};

function InformacionGeneral() {
	return (
		<>
			{/* Contenido de Información General */}
		</>
	);
}

function Horarios() {
	return (
		<>
			{/* Contenido de Horarios */}
		</>
	);
}

function TiposDeKart() {
	return (
		<>
			{/* Contenido de Tipos de Kart */}
		</>
	);
}

function Servicios() {
	return (
		<>
			{/* Contenido de Servicios */}
		</>
	);
}

const tabs = [
	{ id: "informacion-general", label: "Información General", component: InformacionGeneral },
	{ id: "horarios", label: "Horarios", component: Horarios },
	{ id: "tipos-de-kart", label: "Tipos de Kart", component: TiposDeKart },
	{ id: "servicios", label: "Servicios", component: Servicios },
];

export default function () {
	const selected = useSelector((state: RootState) => state.selectedCompany);
	const dispatch = useDispatch();
	const navigator = useNavigate();
	const params = useParams();

	const [circuit, setCircuit] = useState<ICircuit>();

	const [newUser, setNewUser] = useState<string>("");

	const [modalLocation, setModalLocation] = useState<boolean>(false);

	const [error, setError] = useState<string>("");
	const [kart, setKart] = useState<string>("");
	const [time, setTime] = useState<number | undefined>(undefined);
	const [forceReload, setForceReload] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>("informacion-general");
	moment.locale("es");
	useEffect(() => {
		if (selected?.circuit?.id) {
			get(selected?.circuit?.id);
		} else goBack();
	}, []);

	useEffect(() => {
		if (selected?.circuit?.id) {
			if (forceReload) {
				setForceReload(false);
			}

			get(selected?.circuit?.id);
		} else goBack();
	}, [selected, forceReload]);

	const goBack = () => navigator(ERoutes.DASHBOARD);

	const get = async (id: string) => {
		const response = await findByIdCircuitRequest(dispatch, id);

		if (response.data) {
			setCircuit(response.data);
		} else goBack();
	};

	const setValue = (field: string, value: any) => {
		const c = circuit;
		if (!c) return;

		switch (field) {
			case "service":
				c.services = c?.services || [];

				if (c.services.includes(value)) {
					c.services = c.services.filter((s) => s !== value);
				} else {
					c.services.push(value);
				}

				break;
			case "kart":
				c.kartingTypes = c?.kartingTypes || [];
				c.kartingTimes = c?.kartingTimes || [];

				if (c.kartingTypes.includes(value)) {
					c.kartingTypes = c.kartingTypes.filter((s) => s !== value);
					c.kartingTimes = c.kartingTimes.filter((s) => s.kart !== value);
				} else {
					if (!time || time <= 1) {
						sweetalert2.fire(
							`Error`,
							"Es obligatorio introducir un tiempo.",
							"error"
						);
						return;
					}
					c.kartingTypes.push(value);
					c.kartingTimes.push({ kart: value, time });

					setKart("");
					setTime(0);
				}

				break;
			case "type":
				c.type = value;
				break;
			case "name":
				c.name = value;
				break;
			case "open":
				changeOpen(!c.open);
				break;
			case "website":
				c.website = value;
				break;
			case "phone":
				c.phoneNumbers = [value];
				break;
			case "length":
				c.length = parseInt(value);
				break;
			case "newUser":
				setNewUser(value);
				return;
			default:
				return;
		}

		if (c) {
			setCircuit({
				...c,
			});
		}
	};

	const removeUser = async (email: string) => {
		if (!email || !circuit?.id) return;

		const response = await removeCircuitUser(dispatch, email, circuit.id);

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Usuario ${email} removido exitosamente.`, "success")
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire(`Error`, response.data.message, "error");
		}
	};

	const addUser = async () => {
		if (!newUser || !circuit?.id) return;

		const response = await addCircuitUser(dispatch, newUser, circuit.id);

		setNewUser("");

		if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Usuario ${newUser} agregado exitosamente.`, "success")
				.then(() => {
					get(circuit.id);
				});
		} else {
			sweetalert2.fire(`Error`, response.data.message, "error");
		}
	};

	const update = async () => {
		if (!circuit) return;

		const basicInformation: any = {
			action: "basicInformation",
			data: {
				website: circuit.website,
				length:
					typeof circuit.length === "string"
						? parseInt(circuit.length)
						: circuit.length,
				services: circuit.services,
				kartingTypes: circuit.kartingTypes,
				kartingTimes: circuit.kartingTimes,
				address: circuit.address,
			},
		};

		const restricted: any = {
			action: "restrictedInformation",
			data: {
				name: circuit.name,
				type: circuit.type,
			},
		};

		if (circuit.phoneNumbers && circuit.phoneNumbers.length) {
			const phoneNumber: any = {
				action: "phoneNumber",
				data: {
					number: circuit.phoneNumbers[0],
				},
			};
			await updateCircuitRequest(dispatch, phoneNumber, circuit.id);
		}

		const response = await Promise.all([
			updateCircuitRequest(dispatch, basicInformation, circuit.id),
			updateCircuitRequest(dispatch, restricted, circuit.id),
		]);

		if (
			response.length > 0 &&
			response[0].statusCode === EHttpStatusCodes.ACCEPTED
		) {
			sweetalert2
				.fire(
					`Excelente`,
					`Circuito ${circuit.name} modificado exitosamente.`,
					"success"
				)
				.then(() => {
					setCircuit({
						...response[0].data,
					});
				});
		} else {
			sweetalert2.fire(
				`Error`,
				response[0].data.message || response[1].data.message,
				"error"
			);
		}
	};

	const changeOpen = async (value: boolean) => {
		if (!circuit) return;
		const restricted: any = {
			action: "basicInformation",
			data: {
				open: value,
			},
		};

		const response = await updateCircuitRequest(dispatch, restricted, circuit.id);

		if (response.data) {
			setCircuit(response.data);
		}
	};

	const changeLocation = async (address: IAddress) => {
		if (!circuit) return;

		setCircuit((prevState: ICircuit | undefined) => {
			if (!prevState) return prevState;

			return {
				...prevState,
				address,
			};
		});
	};

	const changeImage = async (file: File) => {
		if (!file || !circuit?.id) return;

		await uploadCircuitRequest(dispatch, file, circuit.id);

		get(circuit.id);
	};

	const handleTabChange = (tabId: string) => {
		setActiveTab(tabId);
	};

	if (!circuit)
		return (
			<SecureView permission={permission}>
				<div></div>
			</SecureView>
		);
	return (
		<SecureView permission={permission}>
			<ChangeAddressModal
				show={modalLocation}
				onSubmit={changeLocation}
				onClose={() => setModalLocation(false)}
			/>

			<Card className={"col-12"}>
				<CardBody>
					<Row
						style={{
							justifyContent: "center",
							alignItems: "center",
							paddingBottom: 24,
						}}
					>
						<ProfileImage
							uri={circuit.coverPicture || defaults.circuitCover}
							onChange={changeImage}
						/>
					</Row>

					<Row className="justify-content-center align-items-center mb-3">
						<h2>{circuit.name}</h2>
					</Row>

					<Row className="justify-content-center align-items-center mb-3">
						<p>ID: {circuit.id}</p>
					</Row>

					<Row className="justify-content-center align-items-center mb-3">
						<p>Cliente desde: {moment(circuit.createdAt).format("MMMM YYYY")}</p>
					</Row>

					<Row className="justify-content-center align-items-center mb-3">
						<Col className="d-flex justify-content-center">
							<div className="d-flex" style={{ gap: "30px" }}>
								<FormControlLabel
									disabled
									control={
										<Switch
											color={"primary"}
											disabled
											checked={circuit.premium || false}
										/>
									}
									label="Premium"
								/>
								<FormControlLabel
									control={
										<Switch
											color={"primary"}
											onChange={(e, v) => setValue("open", v)}
											checked={circuit.open || false}
										/>
									}
									label="Abierto"
								/>
							</div>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<ul className="nav nav-tabs justify-content-center">
								{tabs.map((tab) => (
									<li className="nav-item" key={tab.id}>
										<a
												className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
												href={`#`}
												onClick={() => handleTabChange(tab.id)}
										>
											{tab.label}
										</a>
									</li>
								))}
							</ul>
							<div className="tab-content">
								{tabs.map((tab) => (
									<div
										key={tab.id}
										className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""}`}
										id={tab.id}
									>
										<tab.component />
									</div>
								))}
							</div>
						</Col>
					</Row>

					<Row>
						<Form.Group className="mb-3 col-6">
							<Form.Label>ID</Form.Label>
							<Form.Control value={circuit.id} disabled />
						</Form.Group>

						<Form.Group className="mb-3 col-6">
							<Form.Label>Fecha de alta</Form.Label>
							<Form.Control
								value={
									circuit?.createdAt &&
									moment(circuit.createdAt).format("DD/MM/YYYY HH:mm:ss")
								}
								disabled
							/>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group className="mb-3 col-4">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type="text"
								disabled
								placeholder="Circuito 001"
								value={circuit?.name}
								onChange={(e) => setValue("name", e.target.value)}
							/>
						</Form.Group>

						<Form.Group className="mb-3 col-4">
							<Form.Label>Sitio Web</Form.Label>
							<Form.Control
								type="text"
								placeholder="Circuito 001"
								value={circuit?.website}
								onChange={(e) => setValue("website", e.target.value)}
							/>
						</Form.Group>

						<Form.Group className="mb-3 col-4">
							<div className={"form-group"}>
								<label>Tipo de circuito</label>
								<select
									className={"custom-select"}
									defaultValue={"-1"}
									value={circuit.type}
									onChange={(e) => setValue("type", e.target.value)}
								>
									<option value={"-1"}>Seleccione...</option>
									<option value={"indoor"}>Indoor</option>
									<option value={"outdoor"}>Outdoor</option>
									<option value={"indoor/outdoor"}>Indoor / Outdoor</option>
								</select>
							</div>
						</Form.Group>

						<Form.Group className="mb-3 col-6">
							<Form.Label>Teléfono</Form.Label>
							<Form.Control
								type="text"
								placeholder="+34699234234"
								value={
									(circuit.phoneNumbers || []).length > 0
										? circuit.phoneNumbers[0].number
										: ""
								}
								onChange={(e) => setValue("phone", e.target.value)}
							/>
						</Form.Group>

						<Form.Group className="mb-3 col-6">
							<Form.Label>Longitud del circuito (metros)</Form.Label>
							<Form.Control
								type="number"
								placeholder="1000"
								value={circuit.length}
								onChange={(e) => setValue("length", e.target.value)}
							/>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group className="mb-3 col-6">
							<Form.Label>Dirección</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="text"
									placeholder="Gran vía 123"
									value={circuit?.address.address}
									disabled
								/>
								<Button
									onClick={() => setModalLocation(!modalLocation)}
									variant="primary"
									id="button-addon2"
								>
									<i className={"ti-pencil"}></i>
								</Button>
							</InputGroup>
						</Form.Group>

						<Form.Group className="mb-3 col-2">
							<Form.Label>Latitud</Form.Label>
							<Form.Control type="number" value={circuit?.address.latitude} disabled />
						</Form.Group>

						<Form.Group className="mb-3 col-2">
							<Form.Label>Longitud</Form.Label>
							<Form.Control
								type="number"
								value={circuit?.address.longitude}
								disabled
							/>
						</Form.Group>

						<Form.Group className="mb-3 col-2">
							<Form.Label>Código postal</Form.Label>
							<Form.Control
								type="number"
								value={circuit?.address.postalCode}
								disabled
							/>
						</Form.Group>

						<Form.Group className="mb-3 col-3">
							<Form.Label>Ciudad</Form.Label>
							<Form.Control type="text" value={circuit?.address.city} disabled />
						</Form.Group>

						<Form.Group className="mb-3 col-3">
							<Form.Label>Provincia</Form.Label>
							<Form.Control type="text" value={circuit?.address.province} disabled />
						</Form.Group>

						<Form.Group className="mb-3 col-3">
							<Form.Label>País</Form.Label>
							<Form.Control type="text" value={circuit?.address.country} disabled />
						</Form.Group>

						<Form.Group className="mb-3 col-3">
							<Form.Label>Código de País</Form.Label>
							<Form.Control
								type="text"
								value={circuit?.address.countryCode}
								disabled
							/>
						</Form.Group>
					</Row>
				</CardBody>

				<CircuitHours forceReload={() => setForceReload(true)} circuit={circuit} />

				<CardBody>
					<Row>
						<Col sm={6}>
							<h4>Servicios</h4>
							{constants.circuits.services.map((service, i) => {
								return (
									<InputGroup key={i} className="mb-3">
										<InputGroup.Checkbox
											onChange={() => setValue("service", service.id)}
											checked={(circuit.services || []).includes(service.id)}
											aria-label={service.name}
										/>
										<Form.Control
											aria-label={service.name}
											type="text"
											value={translator(service.name)}
											disabled
										/>
									</InputGroup>
								);
							})}
						</Col>

						<Col sm={6}>
							<h4>Tipos de kart</h4>
							{circuit?.kartingTypes?.map((kart) => {
								const kbt = circuit?.kartingTimes?.find((c) => c.kart === kart);
								let time = 0;

								if (kbt) {
									time = kbt.time;
								}

								return (
									<InputGroup className="mb-3">
										<Form.Control value={`${kart} - Mejor tiempo: ${time}`} disabled />
										<Button
											onClick={() => {
												setKart(kart);
												setTime(time);
												setValue("kart", kart);
											}}
											variant="success"
											id="button-addon3"
										>
											<i className={"ti-pencil"}></i>
										</Button>
										<Button
											onClick={() => setValue("kart", kart)}
											variant="danger"
											id="button-addon2"
										>
											<i className={"ti-trash"}></i>
										</Button>
									</InputGroup>
								);
							})}
							<InputGroup className="mb-3">
								<Form.Control
									placeholder="Intoduce el nombre del kart..."
									value={kart}
									onChange={(e) => setKart(e.target.value)}
								/>

								<Form.Control
									placeholder="Intoduce el mejor tiempo"
									value={time}
									onChange={(e) => setTime(Number(e.target.value))}
									type="number"
								/>
								<Button
									onClick={() => setValue("kart", kart)}
									variant="success"
									id="button-addon2"
								>
									<i className={"ti-check"}></i>
								</Button>
							</InputGroup>
						</Col>
					</Row>

					<Button
						variant="success"
						type="button"
						onClick={update}
						className={"mt-3"}
					>
						<i className="ti-save"></i> Guardar
					</Button>

					<Button
						variant="secondary"
						type="button"
						onClick={goBack}
						className={"mt-3 ml-2"}
					>
						Cancelar
					</Button>
				</CardBody>
			</Card>
		</SecureView>
	);
}
