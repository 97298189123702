import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import sweetalert2 from "sweetalert2";
import passwordRecoveryRequest from "../../../core/api/requests/auth/passwordRecoveryRequest";
import { useDispatch } from "react-redux";
import "./PasswordRecovery.css";


export default function PasswordRecovery() {
	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState({ email: "" });

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const dispatch = useDispatch();
	const navigator = useNavigate();

	const request = async (e: any) => {
		e.preventDefault();
		let valid = true;
		let emailError = "";

		if (!validateEmail(email)) {
			emailError = "Correo incorrecto.";
			valid = false;
		}

		if (valid) {
			await passwordRecoveryRequest(dispatch, {
				email,
			});

			sweetalert2
				.fire(
					`Excelente`,
					`Solicitud de recuperación de contraseña creada exitosamente. Si el usuario existe, le llegará un mail a la casilla de correo ${email}`,
					"success"
				)
				.then(() => {
					navigator(ERoutes.AUTH_LOGIN);
				});
		}
	};

	return (
		<div className="flex-center full-height">
			<div className="form-container password-recovery w-75">
				<div className="logo-container">
					<img src="/images/Onpole.png" alt="Logo" />
				</div>
				<h1 className="mb-5">Recuperar contraseña</h1>
				<p className="subtitle mb-5">
					Todo bien. Introduce la dirección de correo electrónico de tu cuenta y te <br></br>
					enviaremos un enlace para restablecer tu contraseña.
				</p>
				<form onSubmit={request}>
					<input
						className={errors.email ? "input-container-error" : ""}
						type="email"
						placeholder="Correo Electrónico"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
					{errors.email && <span className="error">{errors.email}</span>}
					<button className="" type="submit">
						Enviar Solicitud
					</button>
					<Link to="/auth/login" className="link">
						<span>&larr;</span>Volver a Inicio de Sesión
					</Link>
				</form>
			</div>
			<div
				className="image-container w-25"
				style={{ backgroundImage: `url(/images/bannerRight.png)` }}
			/>
		</div>
	);
}
