import CardBody from "../../shared/lib/CardBody";
import {Badge, Button, Col, Row} from "react-bootstrap";
import translator from "../../core/utils/translator";
import React, { useEffect, useState } from "react";
import ICircuit from "../../core/interfaces/ICircuit";
import ModifyHoursModal from "../../shared/modals/ModifyHoursModal";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import {useDispatch} from "react-redux";
import Switch from '@mui/material/Switch';
import { TextField } from "@mui/material";
 
const DEBUG_MODE = window.location.hostname === 'localhost' && true;
export interface IInput {
  circuit: ICircuit;
  forceReload?(): void;
}

export default function (input: IInput) {
  const dispatch = useDispatch()
  const [ show, setShow ] = useState<any>(false);
  const [ showByDay, setShowByDay ] = useState<any>({monday: false, tuesday: false, wednesday: false,  thursday: false, friday: false, saturday: false, sunday: false})
  const [ day, setDay ] = useState<string>('');

  // Object.keys(input.circuit?.operationHours || {}).map((oh) => {
  //   setShowByDay((prevShow: any) => ({
  //     ...prevShow,
  //     [oh]: input.circuit?.operationHours[oh] && input.circuit?.operationHours[oh].length > 0,
  //   }));
  // });

  useEffect(() => {
    if (DEBUG_MODE) {
      console.log('Debug Mode Enabled');
      console.log('Circuit Update Data:', {
        operationHours: input.circuit?.operationHours,
        showByDay: showByDay
      });
      const tempData : any = {
        "monday": [
            {
                "start": "01:00",
                "end": "02:00",
                "month": []
            }
        ],
        "tuesday": [],
        "wednesday": [],
        "thursday": [
            {
                "start": "03:00",
                "end": "05:00",
                "month": []
            }
        ],
        "friday": [],
        "saturday": [],
        "sunday": [],
        "bankHoliday": []
    }
      Object.keys(tempData).map((oh : string) => {
        setShowByDay((prevShow : any) => ({
          ...prevShow,
          [oh]: tempData[oh] && tempData[oh].length > 0,
        }));
      });
      input.circuit.operationHours = tempData;
      return;
    }

    Object.keys(input.circuit?.operationHours || {}).map((oh) => {
      setShowByDay((prevShow: any) => ({
        ...prevShow,
        [oh]: input.circuit?.operationHours[oh] && input.circuit?.operationHours[oh].length > 0,
      }));
    });
  }, []);


  useEffect(() => {
    if (DEBUG_MODE) {
      console.log('Debug Mode Enabled');
      console.log('Day:', day);
      return;
    }

      setShowByDay((prevShow: any) => ({
        ...prevShow,
        [day]: !prevShow[day],
      }));

    // openModal();
  }, [day]);


  const openModal = () => {
    setShowByDay((prevShow: any) => ({
      ...prevShow,
      [day]: true,
    }));
    setShow(true);
  }

  const closeModal = () => {
    setShowByDay((prevShow: any) => ({
      ...prevShow,
      [day]: false,
    }));
    setShow(false);
  }

  const update = async (data: any) => {
    if (!input.circuit) return

    if (DEBUG_MODE) {

      sweetalert2.fire(
        `Debug Mode`,
        `Datos listos para enviar al backend`,
        "info"
      );

      console.log(' Debug Mode Enabled');
      console.log('Data to be sent to backend:', data);
      return;
    }

    const basicInformation: any = {
      action: 'basicInformation',
      data: {
        operationHours: data,
      }
    }

    const response = await updateCircuitRequest(dispatch, basicInformation, input.circuit.id)

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Circuito ${input.circuit.name} modificado exitosamente.`,
        'success',
      ).then(() => {
        setShow(false)
      })
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response.data.message),
        'error',
      )
    }

    if (input.forceReload) {
      input.forceReload()
    }
  }

  const setSchedule = (d: string, value: any) => {
    setDay(d);
    if (value) {
      openModal();
    } else {
      closeModal();
    }
  };

    return <CardBody>
    <ModifyHoursModal
      show={show}
      onClose={() => closeModal()}
      circuit={input.circuit}
      onSave={update}
      selectedDay={day}
    />

    <Row>
      <Col>
        <div className={'time-title-container'}>
          <h4 className={''}>Horarios</h4>

          {/*<Button variant="primary" type="button" onClick={openModal}>*/}
          {/*  Modificar*/}
          {/*</Button>*/}
        </div>

        {Object.keys(input.circuit?.operationHours || {}).map((oh) => {
          return <div className={'time-day-container center'}>
            <Switch className={'mr-3'}
             checked={input.circuit?.operationHours[oh] && input.circuit?.operationHours[oh].length > 0 || showByDay[oh]}
             onChange={(e) => setSchedule(oh, e.target.checked)}
             inputProps={{ 'aria-label': 'controlled' }}
            />
            <h5 className={'mr-3'}>{translator(oh)}</h5>
            <div className={'time-badge-container d-flex center'}>
              {(input.circuit?.operationHours[oh] || []).map((h: any, index: any) => {
                return (
                   // <div className={'d-flex center text-gray'}>
                   //   <div className={"mr-2"}>{h.start}</div>
                   //   <span>-</span>
                   //   <div className={"ml-2"}>{h.end}</div>
                   //   <i className={"ti-pencil text-gray p-2 pointer ml-2"}></i>
                   // </div>
                 <div className={"d-flex center text-gray"}>
                   <TextField 
                     className={"mr-2"} 
                     id={`start-${index}`} 
                     size="small" 
                     variant="outlined"
                     value={h.start}
                     onClick={() => setSchedule(oh, true)}
                     InputProps={{
                       readOnly: true,
                     }}
                   />
                   <span>-</span>
                   <TextField 
                     className={"ml-2"} 
                     id={`end-${index}`} 
                     size="small" 
                     variant="outlined" 
                     value={h.end}
                     onClick={() => setSchedule(oh, true)}
                     InputProps={{
                       readOnly: true,
                     }}
                   />
                   <i className={"ti-check text-green p-2 pointer ml-2"}></i>
                   {input.circuit?.operationHours[oh].length > 1 &&
                   (<i className={"ti-trash text-red p-2 pointer ml-2"}></i>)}
                 </div>
                );
              })
              }
              {(input.circuit?.operationHours[oh] && input.circuit?.operationHours[oh].length > 0 || showByDay[oh]) &&
              (<i className={"ti-plus text-gray p-2 pointer ml-2"} onClick={() => setSchedule(oh, true)}></i>)}
            </div>
          </div>
        })
        }
      </Col>
    </Row>
  </CardBody>
}
