import { useState } from "react";
import { ReactComponent as EyeLine } from "../icons/eye-line.svg";
import { ReactComponent as Eye } from "../icons/eye.svg";

interface PasswordInputProps {
	placeholder: string;
	required?: boolean;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	className?: string;
}

export default function PasswordInput({
	className,
	placeholder,
	required,
	value,
	onChange,
}: PasswordInputProps) {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="password-input-container">
			<input
				type={showPassword ? "text" : "password"}
				placeholder={placeholder}
				required={required}
				value={value}
				onChange={onChange}
				className={`password-input ${className}`}
			/>
			{showPassword ? (
				<EyeLine
					onClick={() => setShowPassword(!showPassword)}
					className={`toggle-password-visibility ${className}`}
				/>
			) : (
				<Eye
					onClick={() => setShowPassword(!showPassword)}
					className={`toggle-password-visibility ${className}`}
				/>
			)}
		</div>
	);
}
