import { useEffect, useState } from "react";
import { Badge, Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import findProductRequest from "../../core/api/requests/circuits/products/findProductRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EModules from "../../core/enums/EModules";
import ICircuitProduct from "../../core/interfaces/ICircuitProduct";
import IPermission from "../../core/interfaces/IPermission";
import { RootState } from "../../core/store/store";
import Paginator from "../../shared/common/Paginator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import CreateCircuitProductModal from "../../shared/modals/CreateCircuitProductModal";
import SeeCircuitProductModal from "../../shared/modals/SeeCircuitProductModal";
import findByIdCircuitRequest from "../../core/api/requests/circuits/findByIdCircuitRequest";
import ICircuit from "../../core/interfaces/ICircuit";

const permission: IPermission = {
	module: EModules.CIRCUIT_PRODUCTS,
	method: ECommonPermissions.FIND,
};

export default function () {
	const permissions = useSelector((state: RootState) => state.permissions);
	const selectedCircuit = useSelector(
		(state: RootState) => state.selectedCompany
	);
  const [circuit, setCircuit] = useState<ICircuit>();

	const dispatch = useDispatch();
	const navigator = useNavigate();

	const [products, setProducts] = useState<ICircuitProduct[]>([]);

	const [count, setCount] = useState<number>(0);

	const [totalPages, setTotalPages] = useState<number>(1);
	const [page, setPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(30);
	const [query, setQuery] = useState<string>("");

	const [createModal, setCreateModal] = useState<boolean>(false);
	const [seeModal, setSeeModal] = useState<string>("");

	const changePageAndFind = (page: number) => {
		find(page);
	};

	const find = async (pageByParam?: number) => {
		if (!selectedCircuit.circuit) return;

		if (pageByParam) setPage(pageByParam);

		const response = await findProductRequest(
			dispatch,
			{
				page: pageByParam || page,
				perPage,
				query,
			},
			selectedCircuit.circuit.id
		);

		if (response?.data) {
			setProducts(response.data?.data || []);
			setCount(response.data?.count || 0);

			setPage(response.data?.pagination?.page || 1);
			setPerPage(response.data?.pagination?.perPage || 30);
			setTotalPages(response.data?.pagination?.total || 1);
		}
	};
  
  const get = async (id: string) => {
    const response = await findByIdCircuitRequest(dispatch, id);

    if (response.data) {
      setCircuit(response.data);
    }
  };

	useEffect(() => {
		find();
    if (selectedCircuit.circuit?.id) {
      get(selectedCircuit.circuit.id);
    }
	}, []);

	useEffect(() => {
		find();
	}, [seeModal, createModal, selectedCircuit.circuit]);

	useEffect(() => {
		setPage(1);
	}, [query]);

	const tableButtons = (
		<div>
			<Button
				onClick={() => setCreateModal(true)}
				className="btn btn-info float-right"
			>
				Nueva tanda
			</Button>
		</div>
	);

	return (
		<SecureView permission={permission}>
			<SeeCircuitProductModal
				show={!!seeModal}
				id={seeModal}
				onClose={() => setSeeModal("")}
			/>

			<CreateCircuitProductModal
				show={createModal}
				onClose={() => setCreateModal(false)}
			/>

			<Row>
				<Card className={"col-12"}>
					<CardBody title={`Tandas ${count}`} buttons={tableButtons}>
						<div className="table-responsive">
							<table className="table table-bordered m-t-30 table-hover contact-list">
								<thead>
									<tr>
										<th>Nombre</th>
										<th>Precio</th>
										<th>Duración de una tanda (s)</th>
										<th>Número de tandas</th>
										<th>Estado</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{products.map((p) => {
										return (
											<tr key={p.id}>
												<td>{p.name}</td>
												<td>
													{p.price} {p.currency.symbol}
												</td>
												<td>{p.duration} segs.</td>
												<td>{p.description}</td>
												<td>
													<Badge>{p.status}</Badge>
												</td>
												<td>
													<Button
														size={"sm"}
														variant={"secondary"}
														onClick={() => setSeeModal(p.id)}
													>
														<i className={"ti-eye"}></i> Ver
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>

							<Paginator total={totalPages} page={page} goTo={changePageAndFind} />
						</div>
					</CardBody>
				</Card>
			</Row>
		</SecureView>
	);
}
